<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Компания:"
            label-for="company_id"
          >
            <b-form-select
              id="company_id"
              v-model="kassaData.organization_id"
              :options="allCompanies"
              :text-field="'name'"
              :value-field="'id'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Тип ордера:"
            label-for="type_kkm"
          >
            <b-form-select
              id="type_kkm"
              v-model="kassaData.type_kkm"
              :options="[{id: null, name: ' - '},{id: 1, name: 'Расход'},{id: 2, name: 'Приход'}]"
              :text-field="'name'"
              :value-field="'id'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!--      <b-row v-if="kassaData.type_kkm === 2">-->
      <!--        <b-col cols="12">-->
      <!--          <b-form-group-->
      <!--            label="Сумма"-->
      <!--            label-for="sum"-->
      <!--          >-->
      <!--            <b-form-input />-->
      <!--          </b-form-group>-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Сумма:"
            label-for="sum"
          >
            <b-form-input
              id="sum"
              v-model="kassaData.sum"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="kassaData.type_kkm !== null">
        <b-col cols="12">
          <b-form-group
            label="Тип операции:"
            label-for="type_operation"
          >
            <b-form-select
              id="type_operation"
              v-model="kassaData.detail_type"
              :options="allDetailType"
              :text-field="'name'"
              :value-field="'id'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="kassaData.details_type === '\\App\\Models\\User'">
        <b-col cols="12">
          <b-form-group
            label="Поиск сотрудника:"
            label-for="search_user"
          >
            <v-select
              id="search_user"
              v-model="kassaData.details_id"
              :options="userOptions"
              label="full_name"
              :reduce="value => value.id"
              @search="onSearchUser"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Описание:"
            label-for="about"
          >
            <b-form-textarea
              id="about"
              v-model="kassaData.about"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="5">
          <b-form-group
            label="Дата:"
            label-for="dateCreate"
          >
            <flat-pickr
              id="dateCreate"
              v-model="kassaData.action_date"
              class="form-control"
              :config="{ enableTime: true, time_24hr: true, dateFormat: 'Y-m-d H:i', defaultDate: 'today'}"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        variant="primary"
        class="mt-2"
        @click="formSubmit"
      >
        {{ kassaData.id ? 'Ок' : 'Ок' }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BForm, BFormGroup, BFormSelect, BButton, BFormTextarea,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// import _ from 'lodash'
import axiosIns from '@/libs/axios'

export default {
  name: 'KassaForm',
  components: {
    BButton,
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    VSelect,
    flatPickr,
  },
  props: ['kassaData', 'allCompanies', 'allDetailType'],
  data() {
    return {
      userOptions: [],
    }
  },
  mounted() {
    axiosIns({
      method: 'GET',
      url: '/users/short-list',
    }).then(response => {
      this.userOptions = response.data.data
    })
  },
  methods: {
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true)
        // _.debounce(() => {
        axiosIns({
          method: 'GET',
          url: '/users/short-list',
        }).then(response => {
          this.userOptions = response.data.data
          loading(false)
        })
        // }, 350)
      }
    },
    formSubmit() {
      this.$emit('formSubmit', true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
