<template>
  <b-card>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <div v-else>
      <Form
        :analytics-data.sync="formData"
        :all-companies="allCompanies"
        :all-detail-type="allDetailType"
        @formSubmit="doFormSave"
      />
    </div>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BCard, BSpinner } from 'bootstrap-vue'
import store from '@/store'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import router from '@/router'
import Form from './Form.vue'

const emptyFormData = Object.freeze({
  organization_id: null,
  type_kkm: null,
  sum: null,
  detail_type: null,
  about: '',
  details_type: null,
  details_id: null,
  action_date: new Date().toISOString()
    .replace(/T/, ' ') // replace T with a space
    .replace(/\..+/, ''),
})

export default {
  name: 'KassaCreate',
  components: {
    BCard,
    BSpinner,
    Form,
  },
  data() {
    return {
      formData: { ...emptyFormData },
      kassaData: null,
      allCompanies: null,
      allDetailType: null,
      loading: true,
    }
  },
  watch: {
    'formData.type_kkm': {
      handler(newKKMTypeId) {
        this.loadAllDetailType(newKKMTypeId)
      },
    },
    'formData.detail_type': {
      handler(detailTypeId) {
        if (detailTypeId === null) {
          this.formData.details_type = null
          this.formData.details_id = null
        } else {
          // eslint-disable-next-line radix
          const detailType = _.find(this.allDetailType, item => item.id === parseInt(detailTypeId))
          if (detailType !== null) {
            this.selectedDetail = detailType
            if (detailType.additional_field !== null) {
              this.formData.details_type = detailType.additional_field
              this.formData.details_id = null
            }
          }
        }
      },
    },
  },
  mounted() {
    Promise.all([
      this.loadCurrentOrder(this.$route.params.id),
      this.loadAllCompanies(),
    ]).then(() => {
      this.loading = false
    })
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    doFormSave() {
      axiosIns({
        method: 'POST',
        url: `/cashiers/${this.$route.params.id}`,
        data: this.formData,
      }).then(() => {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ордер',
            icon: 'DollarSignIcon',
            variant: 'success',
            text: 'Ордер успешно обновлен',
          },
        })
        router.push({ name: 'Analytics' })
      })
    },
    loadCurrentOrder(id) {
      const req = axiosIns({
        method: 'GET',
        url: `/cashiers/${id}`,
      }).then(response => {
        this.formData = _.merge(this.formData, response.data.data)
      })

      return req
    },
    loadAllCompanies() {
      const req = axiosIns({
        method: 'GET',
        url: '/organizations/short-list',
      })

      req.then(response => {
        const defaultCompanies = [{ id: null, name: '' }]
        defaultCompanies.push(...response.data.data)
        this.allCompanies = defaultCompanies
      })

      return req
    },
    loadAllDetailType(id) {
      const req = axiosIns({
        method: 'GET',
        url: `/cashiers/details/${id}`,
      })

      req.then(response => {
        const defaultDetailType = [{ id: null, name: '' }]
        defaultDetailType.push(...response.data.data)
        this.allDetailType = defaultDetailType
      })

      return req
    },
  },
}
</script>
